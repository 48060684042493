.testing_textfield.MuiFormControl-root{
    width: 250px !important;

}

.test_result{
    display: flex;
    flex-direction: row !important;
    align-items: flex-end;
    justify-content: space-evenly;    
    line-height: 150% !important;
    letter-spacing: 0.15px !important;
    color: gray !important;
   
   
}

.content_radio_btn > .MuiButtonBase-root.Mui-checked {
	color:#00afb9 !important;
  }
  .content_radio_btn > .MuiRadio-root{
      padding:9px !important;
  }