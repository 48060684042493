.containerHolder1 {
  width: 85%;
  margin: 0 auto;
  margin-top: 4em;
}

.containerHolder1 h1 {
  font-weight: 600;
  font-size: 21px;
}
.prevQues {
  color: #4bd3c5;
  cursor: pointer;
}
.loginmobile .react-tel-input .form-control {
  width: 100% !important;
  height: 45px !important;
}
.closeIcnOTP {
  position: absolute;
  right: 16px;
  width: 24px;
  cursor: pointer;
  margin-top: -35px;
}
.modal-content1 {
  background-color: #fefefe;
  margin: 8% auto;
  padding: 67px;
  border: 1px solid #888;
  width: 60%;
  border-radius: 20px;
  position: relative;
}
.accordionbg1 {
  position: relative;
  background: linear-gradient(90deg, #4bd3c5 2.97%, #4bd3c5 100%);
  border-radius: 15px;
  padding-top: 23px;
  width: 100%;
  min-height: 25vh;
}

.accSummary1 {
  background: #fff;
  border-radius: 15px;
  box-shadow: 3px 4px 13px lightgrey;
  min-height: 30vh;
}
.submit {
  text-align: center;
  margin-top: 30px;
  margin-bottom: -20px;
}
/* Login Mode css */

.loginMode {
  width: 65%;
  margin: 4em auto;
  box-shadow: 3px 4px 13px lightgrey;
  text-align: center;
  padding: 4em;
  border-radius: 20px;
}

.loginSubmitHolder {
  width: 65%;
  margin: 0 auto;
}
.loginSubmit {
  width: 20% !important;
}
.loginSubmit1 {
  width: 25% !important;
}
.loginSubm {
  background: #f07167;
  border-radius: 16px;
  border-radius: 8px;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: 15%;
  padding:10px
}
.submitFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.widthadj {
  width: 200px;
}
.optinput ::hover {
  border-bottom: none !important;
}
.optinput input {
  text-align: center;
}
.errormess {
  font-size: 12px;
  color: rgb(191, 38, 0);
  margin-top: 10px;
  margin-bottom: -10px;
}
.radiogrouper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  margin-top: 2em;
}

/* 
otp */
.successotp {
  font-size: 12px;
  color: green;
  margin-top: 10px;
  margin-bottom: -10px;
}
.resend {
  margin-top: 2em;
  color: #4ed3c5;
}

.resend > div {
  cursor: pointer;
}
.otpText {
  margin-top: 3em;
}

.lockicon {
  height: 60px;
}

@media only screen and (max-width: 600px) {
  .login_h1
  {
    margin-top:50px
  }

  .loginBg {
    display: none;
  }
  .containerHolder1 {
    margin-top: 0px !important;
  }
  .loginMode {
    width: 91%;
  }
  .loginSubm {
    width: 35% !important;
  }
  .radiogrouper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    margin-top: 2em;
  }

  .radiogrouper > div:nth-child(2) {
    margin-top: 1em;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .loginBg {
    display: none;
  }
  .containerHolder1 {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .loginBg {
    width: 88%;
  }
}
