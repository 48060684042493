.leftSideNav {
  position: relative;
  background: linear-gradient(180deg, #68dec6 2.97%, #69c3cb 100%);
  border-radius: 35px;
  padding-top: 50px;
  width: 75px;
  min-height: 95vh;
  margin-left: 1em;
  margin-top: 1em;

  /* newcss */
  height: -webkit-fill-available;
}

.mainSideLeft {
  display: flex;
}

.mainSideLeft .inner {
  /* width: 10%; */

  /* newcss */
  align-items: stretch;
  flex-direction: column;
  display: flex;

  width: 7%;
}

.profileNav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profileNav img {
  width: 50px;
}

.profileNav > div {
  margin-bottom: 2em;
}

.menuIcons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  margin-top: 4em;
}

.menuIcons > a {
  margin-bottom: 1.5em;
}

.selectedDash {
  background: #fff;
  padding: 5px;
  border-radius: 5px;
}

.mainRightFlex {
  flex: 1;
  /* width:90%; */
  padding: 1em 2em;

  padding: 2em;

  width: 93%;
}

.mobileMenu {
  display: none;
}

.mobileLogo {
  display: none;
}

/* Logout css */
/* .dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
} */

.dropdown {
  position: relative;
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 40px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content div {
  color: #f46674;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* .dropdown:hover .dropbtn {background-color: #3e8e41;} */

@media only screen and (max-width: 769px) {
  .mobileMenu {
    display: block;
  }
  .mainSideLeft .inner {
    display: none;
  }

  .mainRightFlex {
    width: 100%;
  }
  .mobileLogo {
    position: fixed;
    /* padding-block-start: 14px; */
    /* padding-left: 20px; */
    background-color: #4ed3c5;
    /* height: 111px; */
    margin-top: -17px;
    padding: 5px;
    margin-left: -18px;
    width: 65px;
    border-radius: 10px;
    display: inline-flex;
  }
  .headermobile {
    background: white;
    /* width: 100%; */
    /* height: 100%; */
    margin-top: 0px;
    position: fixed;
    padding: 44px;
    display: list-item;
    width: 100%;
  }
}

.modalBox {
  position: fixed; /* Stay in place */
  z-index: 1111111; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 45%; /* Could be more or less, depending on screen size */
  border-radius: 20px;

  position: relative;
}

.closeIcn {
  position: absolute;
  right: 21px;
  width: 24px;
  cursor: pointer;
}

.btnAdjHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 2em 0; */
}
.btnAdjHeader > button:nth-child(1) {
  margin-right: 1em;
  /* margin: 2em 0; */
}

.subBtn10 {
  background: #f07167;
  border: none;
  outline: none;
  padding: 12px;
  width: 20%;
  color: white;
  border-radius: 10px;
  margin-top: 24px;
  font-size: 15px;
  cursor: pointer;
}

.modal-content > div:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
}
