html {
  font-size: 16px;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  outline: 0;
}

:root {
	--gray: gray;
	--btnBackground: #4bd3c5;
	--btn1Background: #f07167;
	--fontFamily: "Poppins", sans-serif;
}


.paperModal {
	position: relative;
	top:160px;
	left: 50%;
    transform: translateX(-50%);
	width:800px;
	height:500px;
	border-radius: 20px;	
	background-color: #fff;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: #fff !important;
}
.MuiTableSortLabel-root:hover {
  color: #fff !important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
  .MuiTableSortLabel-icon {
  color: #fff !important;
}
.span_text {
  color: #4cd3c5;
  margin-right: 10px;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  font-display: swap;
}
button {
  font-family: "Poppins", sans-serif !important;
}

.closeHolder img {
  float: right;
  margin: 20px;
  cursor: pointer;
}
.srchbtn {
  background: #f07167;
  border: none;
  width: 100px;
  padding: 10px;
  font-size: 15px;
  color: white;
  border-radius: 9px;
}
button:disabled {
  opacity: 0.5;
  cursor: none;
}

.srchbtn:focus {
  border: 1px solid #a84f48;
}
.MuiInputBase-input {
  font-family: "Poppins", sans-serif !important;
}
.MuiInputLabel-animated {
  font-family: "Poppins", sans-serif !important;
}
.MuiTableCell-root {
  font-family: "Poppins", sans-serif !important;
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #4cd3c5 !important;
}
.mainHolder {
  min-height: calc(100vh - 93.6px);
}

.MuiFormControl-root {
  width: 100% !important;
}

.btn {
  font-size: 17px;
  color: #fff;
  border-radius: 8px;
  background: #f07167;
  border: unset;
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
}
.select_res {
  width: 18vw !important;
}
.loginGrid {
  padding: 3em 2em;
}
.MuiOutlinedInput-input {
  padding: 13.5px 14px !important;
}
a:visited {
  text-decoration: none;
  color: inherit;
}

a {
  text-decoration: none;
}
button {
  cursor: pointer;
}
.loaderAdj {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}
.MuiCircularProgress-colorPrimary {
  color: #4ed3c5 !important;
}
.MuiTypography-body1 {
  font-family: "Poppins", sans-serif !important;
  font-size: 0.9rem !important;
  line-height: 0.6;
}
.MuiListItem-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.listItems_data {
  margin-top: 5px;
  padding: 5px !important;
  background-color: #eee;
  color: #333;
}
.bm-overlay {
  width: 0% !important;
}
.addbox {
  padding: 0 10px !important;
  border-radius: 0 !important;
}
.search_filter {
  width: 170px;
}

.status_filter {
  width: 230px;
}
.adjs {
  font-size: 12px;
}
.secondRowDropdown{
  margin-top: 25px !important;
}
.multiSelectContainer{
  margin-top: -2px !important;
}

.searchWrapper{   
  overflow-x: hidden; 
  margin-top: 10px !important;
    display: flex !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-bottom: 1px solid rgb(158, 156, 156) !important;
    border-left: 0 !important;
    border-radius: 0 !important;
    height: 40px !important
}

/* .searchWrapper::-webkit-scrollbar {
  height: 0px;
}
.searchWrapper::-webkit-scrollbar-track {
  background: transparent;
} */

.multiSelectContainer li{
  text-transform: capitalize;
}
.multiSelectContainer li:hover{
  background: #eee;
  color:black;
}
.chip{ 
  height: 25px !important;
  background-color: #00AFB9 !important;
  margin-bottom:0 !important; 
  text-transform: capitalize;
}
.highlightOption{
  background:none !important ;
  color:black !important;
}
.optionContainer >li:hover{
  background-color: rgba(0, 0, 0, 0.04) !important;
  color:black !important;
}

.filter_row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}

.MuiInputBase-input {
  font-size: 14px !important;
}

.header_title {
  font-size: 21px;
  text-align: center;
}
.module_title {
  font-size: 20px;
  margin: "10px 0";
}

.removeDose {
	display: flex;
	color: #FFF;
	justify-content: flex-end;
	font-size: 14px;
}

.removebtn {
	background-color:#f07167;
	padding: 3px 8px;
	border-radius: 0.4rem;
	cursor: pointer;
	display: flex;
	align-items: center;
}
/* Modal design code */
.react-confirm-alert-body{
  width: 500px !important;
  font-family: var(--fontFamily) !important;
}
.react-confirm-alert-body > h1 {
	font-size: 20px !important;
  
}
.react-confirm-alert-overlay{
	background: rgba(255, 255, 255, 0.6) !important;
}
.react-confirm-alert-body{
	box-shadow: 0 6px 17px rgb(0 0 0 / 25%) !important;
}

.react-confirm-alert-button-group > button:nth-child(1){

	background-color: var(--btnBackground) !important;
	font-weight: bold;
	color:#fff;
  font-family: var(--fontFamily) !important;
}
.react-confirm-alert-button-group > button:nth-child(2){

	background-color: var(--btn1Background) !important;
	font-weight: bold;
	color:#fff;
  font-family: var(--fontFamily) !important;
}

.Status_name{
	color: var(--btnBackground) !important;
}

.stateLoader{
	width: 50%;		
	display: flex;
	align-items: center;
	justify-content: center;
}

.stateLoader > .MuiCircularProgress-root {
	width: 28px !important;
	height: 28px !important;
}



@media only screen and (max-width: 600px) {
  .select_res {
    width: max-content !important;
    margin-left: 0% !important;
  }
  .filter_row {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .search_filter {
    width: 100%;
  }
  .status_filter {
    width: 100%;
  }

  .search_label {
    display: none;
  }
  .filter_lebel {
    display: none;
  }
  .header_title {
    margin-top: 50px;
  }
  .module_title {
    text-align: center;
  }
}

.MobileError {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
.MuiFormHelperText-root {
  font-family: "Poppins", sans-serif !important;
}
.li_text .MuiListItem-gutters {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pdfViewModal {
  width: 90%;
  height: 70%;
  margin: 12% 7% 10%;
  position: absolute;
  overflow: auto;
}

.MuiButton-root {
  padding: 1px 5px !important;
}

.errorText {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: rgb(191, 38, 0);
  margin-top: 10px;
  margin-bottom: -10px;
}

.loginSubm:focus {
  border: 1px solid #a84f48;
}
.submit .btn:focus {
  border: 1px solid #a84f48;
}

.preview-img {
  width: 200px;
	height: 120px;
  object-fit: contain;
	cursor: pointer;
}

.preview-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	background-position: center;
}

.feedback {
	position: fixed;
	right: 0;
	bottom: 37%;
	height: 160px;
	width: 32px;
	color: #FFF;
	cursor: pointer;
 }

 .feedback:active {
	transform: translateY(0px) scale(0.98);
	filter: brightness(1);
}

.modal_image_preview {
	position: absolute;
	top:75px;
	left:50px;
	width: 700px;
	height: 400px;	
	overflow: hidden;
	text-align: center;
}

.modal_image_preview img {
	width: 100%;
	max-height: 400px;
	height: 100%;
	object-fit: contain;
	/* background-position: center;
	background-size: contain; */
}

/* .MuiTableCell-root .MuiFormControl-root {
  width: 20vw !important;
} */


@media only screen and (max-width: 960px) {
  .secondRowDropdown{
    margin-top: 0px !important;
  }
}
